import BannerSection from 'containers/SaasModern/Banner';

/**
 * @typedef {import("@prismicio/client").Content.HomebannerSlice} HomebannerSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<HomebannerSlice>} HomebannerProps
 * @param {HomebannerProps}
 */
const Homebanner = ({ slice, context }) => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <BannerSection context={context} slice={slice} />
    </section>
  );
};

export default Homebanner;
