import React from 'react';
import {
  HeadLine,
  Container,
  Description,
  ButtonSection,
  BannerComponent,
} from './banner.style';
import BtnGetStarted from 'common/components/Button/BTNGetStarted';

const BannerSection = ({ context, slice }) => {
  return (
    <BannerComponent>
      <Container>
        <HeadLine data-aos='zoom-in'>{slice.primary.headline}</HeadLine>
        <Description data-aos='zoom-in'>
          {slice.primary.subheadline}
        </Description>
        <ButtonSection data-aos='zoom-in'>
          <BtnGetStarted label={slice.primary.ctatext} />
        </ButtonSection>
      </Container>
    </BannerComponent>
  );
};

export default BannerSection;
